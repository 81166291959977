import { useGlobalStore } from "@/stores/global"
import * as api from "@/modules/preference/api/userFilterPreferenceFetcher"

export const useUserFilterPreference = () => {
  const globalStore = useGlobalStore()

  /**
   * Get User Filter Preference
   *
   * @param screenCode
   * @returns
   */
  const getUserFilterPreference = async (screenCode) => {
    try {
      const { data } = await api.getUserFilterPreference(screenCode)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  /**
   * Save User Filter Preference
   *
   * @param screenCode
   * @param payload
   * @returns
   */
  const saveUserFilterPreference = async (screenCode, payload) => {
    try {
      const { data } = await api.saveUserFilterPreference(screenCode, payload)
      globalStore.addSuccessToastMessage("Filter preferences saved successfully!")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    getUserFilterPreference,
    saveUserFilterPreference,
  }
}
