import { reactive, ref, watch } from "vue"
import * as api from "../../api/resiliencePlanFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"

import { ApiCriteria, TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useResiliencePlanList() {
  const globalStore = useGlobalStore()

  // Filter and pagination
  const currentPage = ref(1)

  const apiCriteria = reactive(new ApiCriteria())

  // Initial data
  const selectedCategory = ref({})
  const resiliencePlans = ref([])
  const isLoading = ref(false)

  const { t } = useI18n()

  /**
   *
   * @returns
   */
  async function getResiliencePlanList() {
    try {
      isLoading.value = true
      const { data } = await api.fetchResiliencePlanList(apiCriteria.toQuery())

      if (data && data.data) {
        resiliencePlans.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false

      return data
    } catch (error) {
      isLoading.value = false
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("bcp.errors.listBcps"))
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getResiliencePlanList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getResiliencePlanList()
      }
    },
    { deep: true }
  )

  /**
   * Trigger load data with selected category
   */
  watch(selectedCategory, (data) => {
    if (data && data?.uid?.length > 0) {
      apiCriteria.reset()
      apiCriteria.setSearch("category.uid", data?.uid, TypeCompareEnum.EQUAL)
      apiCriteria.withRelations(["status"])
      getResiliencePlanList()
    }
  })

  /**
   *
   * @param {*} col
   * @param {*} sorted
   */
  function handleSort(col, sorted) {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()

    getResiliencePlanList()
  }

  return {
    getResiliencePlanList,
    currentPage,
    isLoading,
    selectedCategory,
    resiliencePlans,
    apiCriteria,
    handleSort,
    t,
  }
}
