<template>
  <VNetworkGraph :nodes="nodes" :edges="edges" :layouts="layoutData" :configs="configData" ref="graph" :zoomLevel="zoomLevel">
    <slot></slot>
    <template #override-node-label="overrideNodeLabel">
      <slot
        name="override-node-label"
        :nodeId="overrideNodeLabel.nodeId"
        :scale="overrideNodeLabel.scale"
        :text="overrideNodeLabel.text"
        :x="overrideNodeLabel.x"
        :y="overrideNodeLabel.y"
        :config="overrideNodeLabel.config"
        :textAnchor="overrideNodeLabel.textAnchor"
        :dominantBaseline="overrideNodeLabel.dominantBaseline"
      ></slot>
    </template>
  </VNetworkGraph>
</template>

<script>
import * as VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"
import { reactive, ref } from "vue"

export default {
  comppnents: [VNetworkGraph],
  props: {
    nodes: {
      type: Object,
      required: true,
      default: () => {},
    },
    edges: {
      type: Object,
      required: true,
      default: () => {},
    },
    layouts: {
      type: Object,
      default: () => {},
    },
    configs: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: "default",
    },
    reupdateLayout: {
      type: Number,
      default: 0,
    },
    zoomLevel: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcNetwork",
          variant: this.variant,
        })?.el ?? {}
      )
    },

    configData() {
      return reactive(VNetworkGraph.defineConfigs(this.configs))
    },
  },
  watch: {
    reupdateLayout(value) {
      this.updateViewBox()
    },
  },

  setup() {
    const graph = ref()

    return {
      graph,
    }
  },
  data() {
    return {
      layoutData: reactive(
        this.layouts ?? {
          nodes: {},
        }
      ),
    }
  },
  created() {},
  methods: {
    /**
     * Download SVG
     */
    downloadSvg() {
      if (!this.graph) {
        return
      }

      const data = this.graph.getAsSvg()

      const url = URL.createObjectURL(new Blob([data], { type: "octet/stream" }))
      const a = document.createElement("a")
      a.href = url
      a.download = "graph.svg" // filename to download
      a.click()
      window.URL.revokeObjectURL(url)
    },
    getSvgContent() {
      if (!this.graph) {
        return null
      }

      return this.graph.exportAsSvgText()
    },
    updateViewBox() {
      // Animates the movement of an element.
      this.graph?.transitionWhile(() => {
        const nodeSize = (Object.values(this.nodes)[0]?.size ?? 24) * 2
        const box = {}

        Object.entries(this.layoutData?.nodes).forEach(([nodeid, node]) => {
          const x = node?.x ?? 0
          const y = node?.y ?? 0

          // calculate bounding box size
          box.top = box.top ? Math.min(box.top, y) : y
          box.bottom = box.bottom ? Math.max(box.bottom, y) : y
          box.left = box.left ? Math.min(box.left, x) : x
          box.right = box.right ? Math.max(box.right, x) : x
        })
        const viewBox = {
          top: (box.top ?? 0) - nodeSize * 8,
          bottom: (box.bottom ?? 0) + nodeSize * 8,
          left: (box.left ?? 0) - nodeSize * 4,
          right: (box.right ?? 0) + nodeSize * 4,
        }
        this.graph?.setViewBox(viewBox)
      })
    },
  },
}
</script>
