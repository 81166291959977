<template>
  <RLayout>
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("user.users") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>
    <UserSubMenu />
    <EcFlex class="justify-end mt-4">
      <EcButton
        v-permissions:role-create
        variant="primary-sm"
        class="mb-3 lg:mb-0"
        id="btn-create"
        iconPrefix="plus-circle"
        @click="handleCreate()"
      >
        {{ $t("user.button.createRole") }}
      </EcButton>
    </EcFlex>

    <EcTable ref="dataTableRef" :columns="columns" :dataSource="roles" :actionMenu="true" :isLoading="isGetRoleLoading">
      <template v-slot:actionMenu="{ item }">
        <EcFlex class="items-center justify-center h-full">
          <!-- Detail -->
          <EcButton v-permissions:role-update variant="transparent-rounded" class="btn-edit" @click="goToRoleDetail(item.uid)">
            <EcIcon class="text-c0-300" icon="Pencil" width="20" height="20" />
          </EcButton>

          <!-- Delete -->
          <EcButton
            v-permissions:role-delete
            variant="transparent-rounded"
            class="btn-modal-delete"
            @click="handleClickIconDelete(item)"
          >
            <EcIcon
              :class="item.deleteable ? 'text-cError-500' : 'text-c1-300 hover:cursor-not-allowed'"
              icon="trash"
              width="20"
              height="20"
            />
          </EcButton>
        </EcFlex>
      </template>
    </EcTable>
  </RLayout>

  <!-- Modal Delete Role -->
  <teleport to="#layer3">
    <EcModalSimple :isVisible="showDeleteRoleModal" variant="center-3xl" id="modal-delete">
      <EcBox class="text-center">
        <EcFlex class="justify-center">
          <EcIcon class="text-cError-500" width="32" height="32" icon="TrashAlt" />
        </EcFlex>
        <EcBox>
          <EcHeadline as="h5" variant="h5" class="text-xl text-cError-500">
            {{ $t("user.confirmToDelete") }}
          </EcHeadline>
          <EcText class="mt-2 text-base text-c0-500">
            {{ $t("user.cfDeleteNote2") }}
          </EcText>
        </EcBox>
        <EcFlex v-if="!isDeleteRoleLoading" class="justify-center mt-10">
          <EcButton variant="warning" id="btn-delete" @click="handleDeleteRole">
            {{ $t("user.button.delete") }}
          </EcButton>
          <EcButton class="ml-3" variant="tertiary-outline" id="btn-cancel" @click="toggleDeleteModal">
            {{ $t("user.button.cancel") }}
          </EcButton>
        </EcFlex>
        <EcFlex v-else class="justify-center h-10 mt-10">
          <EcSpinner variant="secondary-lg" />
        </EcFlex>
      </EcBox>
    </EcModalSimple>
  </teleport>
</template>

<script>
import UserSubMenu from "@/modules/user/components/UserSubMenu"
import { formatData } from "@/modules/core/composables"
import { useRoleList } from "../use/useRoleList"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewRoles",
  components: { UserSubMenu },
  data() {
    const columns = [
      {
        title: this.$t("role.label.name"),
        key: "label",
        sortKey: "name",
        sortable: true,
      },
      {
        title: this.$t("role.label.description"),
        key: "description",
        sortKey: "description",
        sortable: true,
      },
      {
        title: this.$t("role.label.createdAt"),
        key: "created_at",
        sortKey: "created_at",
        sortable: true,
      },
    ]
    return {
      isLoading: false,
      isDeleteRoleLoading: false,
      roles: [],
      selectedId: null,
      showDeleteRoleModal: false,
      columns,
    }
  },

  setup() {
    const globalStore = useGlobalStore()
    const { getRoles, deleteRole, apiCriteria, isGetRoleLoading } = useRoleList()

    return {
      globalStore,
      getRoles,
      deleteRole,
      apiCriteria,
      isGetRoleLoading,
    }
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.getDateTimeFormat
    },
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    formatData,
    async fetchRoles() {
      this.isLoading = true

      const data = await this.getRoles()

      this.roles = data
      this.isLoading = false
    },

    /**
     * Handle the event when user click delete on modal
     */
    async handleDeleteRole() {
      this.isDeleteRoleLoading = true
      await this.deleteRole(this.selectedId)
      this.isDeleteRoleLoading = false
      this.toggleDeleteModal()
      this.selectedId = null
      this.fetchRoles()
    },

    /**
     * Go to role detail
     * @param {*} uid
     */
    goToRoleDetail(uid) {
      this.$router.push({
        name: "ViewRoleDetail",
        params: { uid },
      })
    },

    /**
     * Create
     */
    handleCreate() {
      this.$router.push({ name: "ViewRoleNew" })
    },

    /**
     * Delete
     * @param {*} item
     */
    handleClickIconDelete(item) {
      if (!item.deleteable) {
        return
      }

      this.selectedId = item.uid
      this.toggleDeleteModal()
    },

    /**
     * Delete modal
     */
    toggleDeleteModal() {
      this.showDeleteRoleModal = !this.showDeleteRoleModal
    },
  },
}
</script>
