import { useGlobalStore } from "@/stores/global"
import * as api from "../../api/activityOwnersFetcher"
import { reactive, ref, watch } from "vue"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"

export function useActivityOwnerList() {
  const globalStore = useGlobalStore()
  const listData = ref([])

  const apiCriteria = reactive(new ApiCriteria())
  const activityOwners = ref([])

  /**
   * Get activityOwner list
   * @returns
   */
  async function getActivityOwnerList() {
    try {
      const { data } = await api.fetchActivityOwnerList(apiCriteria.toQuery())

      activityOwners.value = data?.data || []

      // Paginationd
      apiCriteria.pagination.total = data.total

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getActivityOwnerList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getActivityOwnerList()
      }
    },
    { deep: true }
  )

  /**
   * Get all activityOwners
   * @returns
   */
  async function getAllActivityOwners() {
    try {
      const { data } = await api.fetchAllActivityOwners()
      return data?.data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  return {
    getActivityOwnerList,
    getAllActivityOwners,
    listData,
    apiCriteria,
    activityOwners,
  }
}
