<template>
  <EcFlex :class="[variantCls.root, menuDirection === 'rtl' ? 'text-right' : '']" style="-webkit-overflow-scrolling: touch">
    <EcBox :class="variantCls.buttons" style="-webkit-overflow-scrolling: touch">
      <EcBox class="relative min-h-[99%]">
        <EcBox class="hidden" :class="variantCls.button.for">
          <EcBox
            variant="wrapper"
            class="w-full pr-2 pl-3 lg:pr-5 py-4 lg:pl-10"
            :class="[{ 'flex-row-reverse': menuDirection === 'rtl' }]"
          >
            <EcText class="uppercase font-bold text-cWhite md:text-c3-100 text-lg" :class="[variantCls.button.text.wrapper]">
              MAIN MENU
            </EcText>
          </EcBox>
        </EcBox>

        <!-- Sidebar Menu Item -->
        <RSidebarMenuItem
          v-for="item in menuItems"
          :key="item.component"
          :item="item"
          :componentName="componentName"
          :currentRouteModule="currentRouteModule"
          :menuDirection="menuDirection"
        />

        <!-- Logout -->
        <hr class="h-px my-4 bg-c0-200 border-0" />
        <EcBox class="pb-40" :class="variantCls.button.for">
          <EcButton
            variant="wrapper"
            class="w-full"
            @click="handleClickLogout()"
            :class="[variantCls.button.wrapper, variantCls.button.idle, { 'flex-row-reverse': menuDirection === 'rtl' }]"
          >
            <EcBox :class="variantCls.button.box">
              <!-- Menu Icon -->
              <EcIcon
                icon="Logout"
                width="20"
                height="20"
                :class="[
                  variantCls.button.icon.wrapper,
                  variantCls.button.icon.idle,
                  { 'mr-5': menuDirection === 'ltr' },
                  { 'ml-5': menuDirection === 'rtl' },
                ]"
              />
              <!-- Menu text -->
              <EcText :class="[variantCls.button.text.wrapper, variantCls.button.text.idle]"> Logout </EcText>
            </EcBox>
          </EcButton>
        </EcBox>
        <EcBox id="sidebar-footer" class="mb-4 absolute bottom-0 w-full">
          <EcText class="text-center font-semibold text-base"> {{ globalStore.getTenantName }} Admin Dashboard </EcText>
          <EcText class="text-center text-base font-normal text-c0-300">
            @{{ new Date().getFullYear() }} All rights reserved</EcText
          >
          <EcText class="text-center text-base font-normal text-c0-300"
            >Maintained by
            <EcNavLink class="hover:text-c1-800 text-c1-500" target="_blank" to="https://hatchet.com.au">Hatchet</EcNavLink>
          </EcText>
        </EcBox>
      </EcBox>
    </EcBox>
  </EcFlex>
</template>

<script>
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import { computed } from "vue"
import { useGlobalStore } from "@/stores/global"
import RSidebarMenuItem from "./RSidebarMenuItem.vue"
import { menuModules } from "../../use/useMenuModule"
import EcBox from "@/components/EcBox/index.vue"

export default {
  name: "RSidebarMenu",
  inject: ["getComponentVariants"],
  props: {
    /**
     * @description: Menu direction
     * Supported: "ltr", "rtl"
     */
    menuDirection: {
      type: String,
      default: "ltr",
    },
    unreadNotification: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const globalStore = useGlobalStore()
    const { t } = useI18n()

    const route = useRoute()

    const allowedModuleIds = globalStore?.tenantSettings?.server?.modules?.map((module) => {
      return module.value
    })

    const componentName = computed(() => route?.name)
    const menuItems = computed(() => {
      return menuModules
        .filter((item) => {
          if (item?.landlordUser && !globalStore?.me?.landlord_user) {
            return false
          }

          // Check to see if any sub item is valid
          let haveAtLeast1Item = false

          const hasMenuSubItems = item?.subItems?.length > 0
          if (hasMenuSubItems) {
            const subMenus = item?.subItems?.filter((subMenu) => {
              return allowedModuleIds?.includes(subMenu.module) || subMenu?.placeholder
            })

            item.subItems = subMenus
            haveAtLeast1Item = subMenus?.length > 0
          }

          return allowedModuleIds?.includes(item.module) || (hasMenuSubItems && haveAtLeast1Item) || item?.placeholder
        })
        .map((item) => {
          item.subItems = item.subItems?.map((subItem) => ({ ...subItem, text: t(subItem.text) }))

          return { ...item, text: t(item.text) }
        })
    })

    const currentRouteModule = computed(() => route?.meta?.module)

    return {
      globalStore,
      currentRouteModule,
      componentName,
      menuItems,
    }
  },
  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "RSidebarMenu",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },
  },
  methods: {
    /**
     * Logout
     */
    handleClickLogout() {
      this.globalStore.logout()
    },
  },
  components: { EcBox, RSidebarMenuItem },
}
</script>
