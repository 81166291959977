export default {
  activity: {
    activities: "Activities",

    search: "Search",
    associatedEntity: "Activity Detail",
    deleteFullNote:
      "You can permanently delete the activity from database. This action cannot be reverted and you cannot restore the record anymore.",

    confirmToDelete: "Confirm to Delete",
    cfDeleteNote: "You are going to delete ",
    cfDeleteNote2: "This action cannot be reverted. The record will be deleted permanently.",
    optional: "Optional",
    context: "Context",
    // Delete
    actionCannotBeRevert: "This action cannot be reverted. The record will be deleted permanently.",
    confirmDeleteQuestion: "This action cannot be reverted. Are you sure you want to delete this activity?",
    confirmDeleteRole: "This action cannot be reverted. Are you sure you want to delete this role?",
    confirmDeleteAction: "If so, type the activity name into the box below to confirm",

    labels: {
      division: "Division",
      businessUnit: "Business Unit",
      entityName: "Entity Name",
      activityName: "Activity Name",
      createdAt: "Created At",
      updatedAt: "Last Updated",
      step: "Step",
      yes: "Yes",
      no: "No",
      name: "Name of activity",
      description: "Description",
      roles: "Who is required to deliver this activity?",
      alternative_roles: "Alternate roles that could deliver this activity?",
      min_people: "Minimum number of people that could support this function?",
      wfh: "Can this be delivered remotely (e.g. WFH)?",
      utilities: "Utilities required to support function",
      enableRemote: "What enables remote access?",
      unableEnableRemote: "If unable to deliver remotely, what sites / buildings are required?",
      software: "What applications or software are required?",
      dependencies: "Internal Dependencies outside of your area",
      suppliers: "Critical Suppliers",
      dataStorage: "IT Data Storage",
      data: "Data",
      storageLocation: "Storage location",
      reliantOnServers: "Are you reliant on servers to access data?",
      equipments: "Equipment requirements",
      status: "Status",
      rolesActivity: "Roles",
      priority: "Priority",
      internal_dependencies: "Internal Dependencies",
      sharp: "#",
      primary_location: "Primary Location",
      bcm_has_been_accessed: "Has BCM been assessed for these outsourced providers?",
      bcm_accessed_method: "Assessed method for {supplierName}",
      mtpd: "MTPD",
    },
    buttons: {
      add: "Add",
      create: "Create",
      update: "Update",
      next: "Next",
      save: "Save and Finish",
      saveAsDraft: "Save Draft",
      finish: "Finish",
      back: "Back",
      cancel: "Cancel",
      close: "Close",
      exportActivities: "Export Activities",
      timeImpactAnalysis: "Time Impact Analysis",
      addActivity: "Add Activity",
      addBulkActivities: "Add Bulk Activities",
      edit: "Edit",
      view: "View",
      confirm: "Confirm",
      delete: "Delete",
      deleteActivity: "Delete Activity",
      activityRole: "Activity Role",
      addActivityRole: "New Activity Role",
      continueBulkAddActivities: "Continue add activities",
      yes_delete: "Yes, delete",
      saveFilter: "Save Filter",
    },

    title: {
      newActivity: "New Activity",
      addBulkActivities: "Add Bulk Activities",
      editActivity: "Update Activity",
      activityDetail: "Activity Detail",
      remote: "Remote access",
      software: "Software / Technology",
      tolerant: "Maximum Tolerable Period of Disruption (MTPD)",
      rto: "Recovery Time Objective (RTO)",
      dependencies: "Internal Dependencies And Critical Suppliers",
      confirmToCancel: "Confirm to cancel",
      cancelQuestion: "Do you wish to cancel doing activity?",
      confirmToDeleteBulkDraft: "Confirm to delete",
      deleteBulkDraftQuestion:
        "Do you wish to delete doing draft input activities? The data will be deleted permanently from system",
    },

    messages: {
      createdToNextStep: "Created new activity, redirect to next step...",
      updatedActivity: "Updated activity",
      toNextStep: "Updated activity",
      updatedActivityNext: "Updated activity",
      createSuccess: "Created new activity.",
      createRoleSuccess: "Create New Role successfully!",
      updateRoleSuccess: "Update Role successfully!",
      deleteSuccess: "Activity has been delete successfully!",
      code: "Code",
    },
    // Errors
    errors: {
      listDivision: "Unable to retrive corresponding division",
      listBU: "Unable to retrive corresponding BU",
      list: "Unable to retrive corresponding data",
      updateActivity: "Error to update activity, please retry",
      updateActivityRole: "Error to update role, please retry",
      listActivity: "Unable get list of Activities",
      listAddBulkActivityDraft: "Unable get list of draft add bulk activities",
      download: "Error occured, please try again",
      duplicateName: "Duplicated activity name",
      invalidRTO: "Must be less than the MTPD",
      invalidMTPD: "Must be greater than the RTO",
    },

    placeholders: {
      loading: "Loading...",
      division: "--- Division ---",
      bu: "--- Business Unit ---",
      noData: "No data found - Press enter to add new record",
      noDataPermission: "No data found - You need create user permission to add new record",
      noDataResource: "No data found - Press enter to add new {resourceName}",
    },

    assignee: {
      label: "Activity Owner",
      uid: "uid",
      name: "Activity Owner",
    },
    owner: {
      new: "Create Activity Owner",
      email: "Email",
      name: "Name",
      first_name: "First Name",
      last_name: "Last Name",
    },
    division: {
      label: "Division",
      uid: "uid",
      name: "Division",
    },
    bu: {
      label: "Business Unit",
      uid: "uid",
      name: "Business Unit",
    },

    tooltips: {
      cancel: "Cancel doing activity",
      tolerantTime:
        "If the disruption impact is dependent on the time of the week/month/year that the disruption occurs, please use ‘worst case’ scenario to assess MTPD and explain below",
      rtoPeriod: "If you are unsure, select the timeframe that is half the MTPD.",
      addDependency: "Add a new dependency",
      addInternalDependency: "Add a new internal dependency",
      dependencyReload: "Reload dependencies list",
      internalDependencyReload: "Reload internal dependencies list",
      addSupplier: "Add a new supplier",
      supplierReload: "Reload suppliers list",
      addMoreUtility: "Add more utility",
      addMoreApp: "Add more application",
      addEquipment: "Add more equipment",
      requireField: "This field is required in order to complete the activity, but you can skip it now and fill it out later",
      tolerant: "Maximum Tolerable Period of Disruption",
      rto: "Recovery Time Objective",
      activity_saved: "Activity has been saved",
      activity_unsaved: "Activity has an error on saving. Please double-check input data",
    },

    tolerant: {
      time: "How long could the business operate without this function before it becomes a ‘moderate’ impact according to your risk scales?",
      timeOf: "If dependent on time of the week/month/year, please outline here:",
      reason: "Justification for MTPD - why have you chosen this time?",
      timeRequired: "Time selection is required",
    },

    rto: {
      period:
        "Period of time within which minimum levels of services and/or products and the supporting systems, applications, or functions are to be recovered after a disruption has occurred.",
      testedRealTime: "Has the RTO been tested in real-time?",
      scenario: "What scenario/s would disrupt this activity the most?",
      existingWorkaround: "Are there existing workarounds for {disruptionName}?",
      workaroundSolution: "Please enter your solution for {disruptionName}",
      workaroundEnacted: "How long could this workaround feasibly be enacted?",
      workaroundFeasiblyActivation: "Please enter feasible activation for {disruptionName}",
      timeRequired: "Time selection is required",
      mld_rpo: "MDL / RPO",
      mld_rpo_desc:
        "Maximum Data Loss or Recovery Point Objective. \n" +
        "The maximum amount of data loss that the business can tolerate in terms of time. Typically measured by how far back the business is able to reconstruct data.",
    },
    role: {
      new: "New Role",
      edit: "Edit Role",
      detail: "Role Detail",
      delete: "Delete Role",
      noteDelete:
        "You can permanently delete the role from database. This action cannot be reverted and you cannot restore the record anymore.",
      name: "Name",
      description: "Description",
      createdAt: "Created At",
    },

    modal: {
      buttons: {
        cancel: "Cancel",
        create: "Create",
        save: "Save",
      },
      labels: {
        saveFilterPreference: "Are you sure want to save these filter settings?",
      },
    },
    bulkAddActivities: {
      messages: {
        saveDraftSuccess: "Draft saved",
        saveSuccess: "Bulk activities save successfully.",
        saveRequiredAtLeastOneRecord: "You must input at least 1 record to save draft.",
        invalidData:
          "Please double-check the input data. No record was found to save, a duplicate name was detected, or invalid settings for RTO and MTPD.",
        saveError: "Unable to save bulk activities records. Please double-check the input data and try again.",
      },
      columns: {
        excel_process_no: "Process No.",
        excel_business_area: "Business Area",
        excel_business_unit: "Business Unit",
        excel_activity: "Process / Function / Activity",
        excel_primary_location: "Primary location this function is performed from",
        excel_who_required: "Who (and how many) are the people required for the successful delivery of this function? (by role)",
        excel_who_required_alternate: "Who are the alternates that could support this function? (by role)",
        excel_minimum_people_support: "What is the minimum number of people that could support this function?",
        excel_can_remote_access: "Can this function be delivered through Remote Access (e.g. WFH)",
        excel_what_sites_building_required:
          "If function cannot be delivered remotely - what sites / buildings are required? (e.g. Warehouse, mail centre etc)",
        excel_software_needed: "Software or Application Needs (e.g. MS Office)",
        excel_reliant_on_the_organisation_server: "Are you reliant on the organisation's servers?",
        excel_mdl_rpo:
          "MDL / RPO\n" +
          "Maximum Data Loss or Recovery Point Objective\n" +
          "The maximum amount of data loss that the business can tolerate in terms of time. Typically measured by how far back the business is able to reconstruct data.",
        excel_physic_equipment: "Physical Equipment Needs (e.g. Laptop or PC, Printer)",
        excel_utilities: "Utilities Required to Support Function (e.g. Power, Internet, Gas)",
        excel_internal_dependencies: "Internal Dependencies outside of your area (e.g. HR, Legal)",
        excel_critical_suppliers:
          "Critical Suppliers and/or outsourced arrangements for this Function (e.g. Print Company, Bank etc..)",
        excel_BCM_access_these_outsource: "Has BCM been assessed for these outsourced providers?",
        excel_time_MTPD: "MTPD: How long could the business operate without this function before it becomes a 'moderate' impact?",
        excel_justification_for_MTPD: "Justification for MTPD",
        excel_RTO_minimum:
          "RTO - Period of time within which minimum levels of services and/or products and the supporting systems, applications, or functions are to be recovered after a disruption has occurred. Must be less than the MTPD.",
        excel_RTO_realtime: "Has the RTO been tested in real-time?",
        excel_scenario_threat_ranking:
          "Threat Ranking - Continuity Five\n" +
          "Please rank the following in order of highest to lowest threat to each specific business function.\n" +
          "1 = Loss of IT\n" +
          ", 2 = Loss of critical supplier\n" +
          ", 3 = loss of key personnel\n" +
          ", 4 = loss of utilities\n" +
          ", 5 = loss of access to site",
        excel_workaround: "Workaround: {disruptionName}",
        excel_workaround_solution: "Solution for {disruptionName}",
        excel_workaround_feasible_duration: "Feasible Duration",
        excel_workaround_feasible_activation: "Feasible Activation",
      },
    },
  },
}
