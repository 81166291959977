import { reactive, ref, watch } from "vue"
import * as api from "../api/activityFetcher"
import { fetchListDraftBulkActivities } from "../api/bulkActivitiesFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useActivityList() {
  const globalStore = useGlobalStore()

  const activities = ref([])
  const isLoading = ref(false)
  const apiCriteria = reactive(new ApiCriteria("list_activity"))

  apiCriteria.pagination.size = 20

  const { t } = useI18n()

  /**
   *
   * @returns
   */
  async function getActivityList() {
    try {
      isLoading.value = true
      const { data } = await api.fetchActivities(apiCriteria.toQuery())

      if (data && data.data) {
        activities.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("activity.errors.listActivity"))
      activities.value = []
      isLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getActivityList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getActivityList()
      }
    },
    { deep: true }
  )

  /**
   *
   * @param {*} data
   */
  async function handleSort(col, sorted) {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await getActivityList()
  }

  /**
   *
   * @param {*} divisionUid
   * @returns
   */
  async function fetchActivityListByDivisionUid(divisionUid) {
    try {
      const { data } = await api.fetchActivityListByDivisionUid(this.$t("activity.firstOrganizationId"), divisionUid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("activity.errors.listActivity"))
    }
  }

  /**
   * Download activities
   * @returns
   */
  async function downloadActivities(divisionUid, businessUnitUid) {
    try {
      const { data } = await api.downloadActivities(divisionUid, businessUnitUid)

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("activity.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Activities_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("activity.errors.download"))
      return false
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const softDeleteActivity = async (uid) => {
    try {
      const { data } = await api.deleteActivity(uid)

      globalStore.addSuccessToastMessage(t("activity.messages.deleteSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * Bulk drafts
   * @returns
   */
  async function getListAddBulkActivitiesDraft() {
    try {
      const { data } = await fetchListDraftBulkActivities()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("activity.errors.listAddBulkActivityDraft"))
    }
  }

  return {
    getActivityList,
    downloadActivities,
    fetchActivityListByDivisionUid,
    softDeleteActivity,
    getListAddBulkActivitiesDraft,
    activities,
    isLoading,
    apiCriteria,
    t,
    handleSort,
  }
}
