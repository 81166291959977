<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("bia.assessments") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Filter-->
    <EcBox class="items-center mt-6 grid grid-cols-1 md:grid-cols-2">
      <!-- Filter -->
      <EcFlex class="items-center">
        <!-- From -->
        <RDataFilter ref="filterBoxRef" v-model="apiCriteria" :filterOptions="filterOptions" @appliedFilters="getBIAList" />
      </EcFlex>

      <!-- Actions -->
      <EcFlex class="justify-center md:justify-end mt-6 md:mt-0">
        <!-- Add BIA -->
        <EcButton
          v-permissions:bia-create
          class="mb-3 lg:mb-0"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickAddBIA"
        >
          {{ $t("bia.buttons.addBIA") }}
        </EcButton>

        <!-- Export BIAs -->
        <EcButton
          v-permissions:bia-download
          class="col-end-2 ml-2 mb-3 lg:mb-0"
          :iconPrefix="exportBIAsIcon"
          variant="primary-sm"
          @click="handleClickDownloadBIAs"
        >
          {{ $t("bia.buttons.exportBIAs") }}
        </EcButton>
      </EcFlex>
    </EcBox>

    <!-- Table -->
    <EcTable
      ref="dataTableRef"
      name="tbl-bia-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="bias"
      :actionMenu="true"
      :menuItems="menuItems"
      @sorted="handleSort"
    >
      <template v-slot:status-name="{ item }">
        <EcFlex class="w-full items-center justify-center">
          <EcBadge :class="[item?.status?.tag_color, item?.status?.text_color, 'px-1 py-1 rounded-full min-w-32']">
            {{ item?.status?.name }}
          </EcBadge>
        </EcFlex>
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = parseInt(size)
          }
        "
      />
    </EcFlex>

    <!-- Modal  delete BIA -->
    <teleport to="#layer1">
      <EcPopup
        v-model="isModalDeleteOpen"
        type="danger"
        :footerBorder="false"
        :title="$t('bia.labels.confirmToDelete')"
        :bodyText="$t('bia.labels.confirmDeleteQuestion')"
        :bodySubText="toDeleteItem?.name"
        @overlayClick="handleCloseDeleteModal"
        accept="Delete"
        @onAccept="handleClickDeleteBIA"
      >
      </EcPopup>
    </teleport>
  </RLayout>
</template>

<script>
import { useBIAList } from "@/modules/assessment/use/useBIAList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import { useBIAStatusEnum } from "../../../readybc/composables/use/useBIAStatus"
import { useBIADetail } from "@/modules/assessment/use/useBIADetail"

export default {
  name: "ViewBCPList",
  setup() {
    // Pre load
    const globalStore = useGlobalStore()
    const { getBIAList, isLoading, handleSort, downloadBIAs, exportBIARecord, bias, apiCriteria } = useBIAList()
    const { deleteBIA } = useBIADetail()

    const { statuses } = useBIAStatusEnum()

    return {
      globalStore,
      getBIAList,
      isLoading,
      handleSort,
      downloadBIAs,
      exportBIARecord,
      deleteBIA,
      bias,
      statuses,
      apiCriteria,
    }
  },
  data() {
    return {
      headerData: [
        { key: "name", title: this.$t("bia.labels.name"), sortable: true },
        { key: "status.name", title: this.$t("bia.labels.status"), sortable: true, sortKey: "status_id" },
        { key: "report_count", title: this.$t("bia.labels.report_num") },
        { key: "created_at", title: this.$t("bia.labels.createdAt"), sortable: true, date: true },
      ],

      menuItems: [
        {
          label: "Export",
          icon: "export",
          iconClass: "text-c1-800",
          callback: this.handleClickExportBIA,
        },
        {
          label: "Edit",
          icon: "edit",
          callback: this.handleClickEditBIA,
        },

        {
          label: "Delete",
          icon: "delete",
          iconClass: "text-cError-700",
          callback: this.handleOpenDeleteModal,
        },
      ],

      filterOptions: [
        {
          key: "name",
          title: this.$t("bia.labels.name"),
        },
        // {
        //   key: "status.name",
        //   title: this.$t("bia.labels.status"),
        // },
        {
          key: "created_at",
          title: this.$t("bia.labels.createdAt"),
          type: "date",
        },
      ],

      isDownloading: false,
      isExporting: false,
      isModalDeleteOpen: false,
      // BIA uid to delete
      toDeleteItem: null,
    }
  },
  mounted() {
    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.getBIAList()
  },
  computed: {
    /**
     * Format date
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },

    /**
     * Export Icone
     */
    exportBIAsIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },

    /**
     * Filtered
     */
    filteredBIAs() {
      return this.bias
    },
  },

  methods: {
    formatData,

    /**
     * Add date filter to filter querry
     */
    addDateToFilterQuery() {
      // Always add date filter before get list
      this.filters.filter[1] = {
        name: "created_at",
        type: "between",
        value: `${this.dateFilter.from} 00:00:00, ${this.dateFilter.to} 23:59:59`,
      }
    },

    // Handle events
    /**
     * Download
     */
    async handleClickDownloadBIAs() {
      this.isDownloading = true
      await this.downloadBIAs()
      this.isDownloading = false
    },

    /**
     * Add new activity
     */
    handleClickAddBIA() {
      goto("ViewBIANew")
    },

    /**
     *
     * @param {*} uid
     */
    async handleClickExportBIA(item) {
      await this.exportBIARecord(item.uid)
    },

    /**
     *
     * @param {*} uid
     */
    handleClickEditBIA(item) {
      goto("ViewBIADetail", {
        params: {
          uid: item.uid,
        },
      })
    },

    /**
     *
     * @param {*} item
     */
    async handleClickDeleteBIA() {
      if (this.toDeleteItem) {
        await this.deleteBIA(this.toDeleteItem?.uid)
        this.handleCloseDeleteModal()
      }
    },

    /**
     * Open delete resource modal
     */
    handleOpenDeleteModal(item) {
      this.toDeleteItem = item
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete resource modal
     */
    handleCloseDeleteModal() {
      this.toDeleteItem = null
      this.isModalDeleteOpen = false
      this.getBIAList()
    },

    // ==== PRE-LOAD ==========
  },
  components: {},
}
</script>
