<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-2 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("report.titleListReport") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Filter-->
    <EcBox class="xl:grid-cols-2 lg:grid-cols-1 grid sm:grid-cols-1 md:grid-cols-1 gap-2 mt-4 lg:mt-2">
      <EcBox class="grid grid-cols-5 lg:mt-2">
        <!-- Data filter -->
        <EcFlex class="items-center">
          <RDataFilter ref="filterBoxRef" v-model="apiCriteria" :filterOptions="filterOptions" @appliedFilters="getReportList" />
        </EcFlex>
        <EcFlex class="col-span-4 grid grid-cols-2 gap-2"> </EcFlex>
      </EcBox>

      <!-- Buttons -->
      <EcFlex class="flex-end w-full w-flex-row justify-end gap-2">
        <EcButton
          v-permissions:report-create
          class="mb-3 lg:mb-0"
          id="btn-add-activity"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickGenerateNewReport"
        >
          {{ $t("report.buttons.generateReport") }}
        </EcButton>
      </EcFlex>
    </EcBox>

    <EcFlex class="mt-4">
      <EcTable
        ref="dataTableRef"
        name="tbl-reports"
        :isLoading="isGetReportLoading"
        :columns="headerData"
        :dataSource="filteredReports"
        :actionMenu="true"
        :rowClicked="handleClickRowItem"
        :defaultMenuItemVisible="tableMenuItemVisible"
        @sorted="handleColumnSort"
        @onEdit="handleClickEditReport"
        @onDelete="handleClickDeleteReport"
      >
        <template v-slot:created_by="{ item }">
          <EcFlex class="items-center w-full">
            {{ item?.user ? `${item?.user?.first_name} ${item?.user?.last_name}` : "N/A" }}
          </EcFlex>
        </template>

        <template v-slot:report_type="{ item }">
          <EcBadge class="px-2 py-1 rounded-full min-w-24">
            {{ item?.report_type.toUpperCase() }}
          </EcBadge>
        </template>

        <template v-slot:created_at="{ item }">
          <EcBadge class="px-2 py-1 rounded-full min-w-24">
            {{ formatData(item.created_at, dateTimeFormat) }}
          </EcBadge>
        </template>

        <template v-slot:is_completed="{ item }">
          <EcBadge class="px-2 py-1 rounded-full min-w-24">
            <EcIcon icon="CheckCircle" class="text-cSuccess-500" v-if="item?.is_completed" />
          </EcBadge>
        </template>

        <template v-slot:status="{ item }">
          <EcBadge
            :class="[
              'px-2 py-1 rounded-full min-w-24 text-center',
              refineReportStatus(item?.status) === 'success'
                ? 'text-cWhite bg-cSuccess-500'
                : refineReportStatus(item?.status) === 'failed'
                ? 'text-cWhite bg-cError-500'
                : 'border-1',
            ]"
          >
            {{ refineReportStatus(item?.status) }}
          </EcBadge>
        </template>

        <template v-slot:completed_at="{ item }">
          <EcBadge class="px-2 py-1 rounded-full min-w-24">
            {{ formatData(item.completed_at, dateTimeFormat) }}
          </EcBadge>
        </template>
      </EcTable>
    </EcFlex>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.setPageSize(size)
          }
        "
      />
    </EcFlex>

    <!-- Modals -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-3xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="30" height="30" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-2xl">
              {{ $t("report.modal.title.confirmToDelete") }}
            </EcHeadline>
            <EcText class="text-c0-500 mt-4">
              {{ $t("report.modal.cfDeleteNote") }}
            </EcText>
            <EcText class="text-lg font-semibold">
              {{ this.selectedToDeleteReport.name }}
            </EcText>
            <EcText class="text-c0-500 mt-2">
              {{ $t("report.modal.cfDeleteNote2") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="warning" @click="handleDeleteReport">
              {{ $t("report.buttons.delete") }}
            </EcButton>
            <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("report.buttons.cancel") }}
            </EcButton>
          </EcFlex>

          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>

    <!-- End modal -->
  </RLayout>
</template>

<script>
import { useI18n } from "vue-i18n"
import { useReport } from "@/modules/report/use/useReport"
import { useReportList } from "@/modules/report/use/useReportList"
import { useReportTemplate } from "@/modules/report/use/useReportTemplate"
import { formatData, goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import EcHeadline from "@/components/EcHeadline/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import RLayout from "@/modules/core/components/common/RLayout.vue"
import EcTable from "@/components/EcTable/index.vue"
import EcIcon from "@/components/EcIcon/index.vue"

export default {
  name: "ViewReportList",
  data() {
    return {
      isLoading: false,
      isDeleteLoading: false,
      isModalDeleteOpen: false,
      selectedToDeleteReport: null,
      availableReportTemplates: [],
      filterOptions: [
        {
          key: "report_type",
          title: this.$t("report.labels.type"),
          type: "select",
          data: this.availableReportTemplates,
          nameKey: "report_name",
          valueKey: "type",
        },
        {
          key: "name",
          title: this.$t("report.labels.name"),
        },
        {
          key: "created_at",
          title: this.$t("report.labels.createdAt"),
          type: "date",
        },
      ],
      headerData: [
        {
          key: "name",
          title: this.$t("report.labels.name"),
          sortable: true,
        },
        {
          key: "report_type",
          title: this.$t("report.labels.type"),
          sortable: true,
        },
        {
          key: "created_by",
          title: this.$t("report.labels.createdBy"),
          sortable: true,
        },
        {
          key: "status",
          title: this.$t("report.labels.status"),
          sortable: true,
        },
        {
          key: "is_completed",
          title: this.$t("report.labels.isCompleted"),
        },
        {
          key: "created_at",
          title: this.$t("report.labels.createdAt"),
          sortable: true,
        },
        {
          key: "completed_at",
          title: this.$t("report.labels.completedAt"),
        },
      ],
      currentPage: 1,
      tableMenuItemVisible: {
        edit: this.$permissions("report.read"),
        delete: this.$permissions("report.delete"),
      },
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { deleteReport, refineReportStatus } = useReport()
    const { getRunnableReportTemplateTypes } = useReportTemplate()
    const { apiCriteria, listReports, getReportList, handleColumnSort, isGetReportLoading } = useReportList()
    const { t } = useI18n()

    return {
      listReports,
      isGetReportLoading,
      handleColumnSort,
      getReportList,
      apiCriteria,
      deleteReport,
      refineReportStatus,
      globalStore,
      getRunnableReportTemplateTypes,
      t,
    }
  },
  async mounted() {
    await this.apiCriteria.bindCriteria()
    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.getReportList()
    this.fetchTemplateTypes()
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
    filteredReports() {
      return this.listReports
    },
  },
  methods: {
    formatData,

    handleClickGenerateNewReport() {
      goto("GenerateReport")
    },

    handleClickEditReport(report) {
      goto("ViewReportDetail", {
        params: {
          uid: report?.uid,
        },
      })
    },

    handleClickDeleteReport(report) {
      this.isModalDeleteOpen = true
      this.selectedToDeleteReport = report
    },

    async handleDeleteReport() {
      try {
        const response = await this.deleteReport(this.selectedToDeleteReport.uid)
        if (response) {
          this.globalStore.addSuccessToastMessage(this.t("report.messages.deleteReportSuccess"))
          this.getReportList()
        }
      } catch (_) {
      } finally {
        this.handleCloseDeleteModal()
      }
    },

    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
      this.selectedToDeleteReport = null
    },

    handleClickRowItem(report) {
      goto("ViewReportDetail", {
        params: {
          uid: report?.uid,
        },
      })
    },

    handleApplyFilters(filterData) {
      this.filterData = filterData

      this.advanceOptionsLabel()
    },

    async fetchTemplateTypes() {
      this.isLoading = true
      const response = await this.getRunnableReportTemplateTypes()
      if (response) {
        this.availableReportTemplates = response.data
      }
      this.isLoading = false
    },
  },

  components: { EcIcon, EcTable, RLayout, EcFlex, EcHeadline },
}
</script>
