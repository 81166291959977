import { reactive, ref, watch } from "vue"
import * as api from "../api/assessmentFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { downloadFromBlob } from "@/readybc/composables/helpers/downloadHelper"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useBIAList() {
  const globalStore = useGlobalStore()

  // Initial data
  const bias = ref([])
  const isLoading = ref(false)

  const { t } = useI18n()

  const apiCriteria = reactive(new ApiCriteria())
  apiCriteria.withRelation(["status"])

  /**
   *
   * @returns
   */
  async function getBIAList() {
    try {
      isLoading.value = true

      const { data } = await api.fetchBIAList(apiCriteria.toQuery())

      if (data && data.data) {
        bias.value = data.data

        apiCriteria.setTotalPage(data.total)
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("bia.errors.listBIAs"))
      isLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getBIAList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getBIAList()
      }
    },
    { deep: true }
  )

  /**
   *
   * @param {*} data
   */
  async function handleSort(col, sorted) {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await getBIAList()
  }

  /**
   * Download bcps
   * @returns
   */
  async function downloadBIAs() {
    try {
      const { data } = await api.downloadBIAs()

      if (!data) {
        globalStore.addErrorToastMessage(t("bia.errors.download"))
        return
      }

      downloadFromBlob(data, "Business_Impact_Assessments", "xlsx")
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("bia.errors.download"))
      return false
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  async function exportBIARecord(uid) {
    try {
      const { data } = await api.exportBIA(uid)

      if (!data) {
        globalStore.addErrorToastMessage(t("bia.errors.download"))
        return
      }

      downloadFromBlob(data, `Business_Impact_Assessment`, "zip")
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("bia.errors.download"))
      return false
    }
  }

  return {
    getBIAList,
    downloadBIAs,
    exportBIARecord,
    bias,
    isLoading,
    t,
    apiCriteria,
    handleSort,
  }
}
