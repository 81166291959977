import { useGlobalStore } from "@/stores/global"
import * as api from "../api/applicationFetcher"
import { reactive, ref, watch } from "vue"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { useI18n } from "vue-i18n"

export function useApplicationList() {
  const globalStore = useGlobalStore()
  const listData = ref([])
  const currentPage = ref(1)

  const isLoading = ref(false)
  // Initial data

  const apiCriteria = reactive(new ApiCriteria())

  const { t } = useI18n()

  /**
   *
   * @param {*} activityStatistic
   * @returns
   */
  async function listApplications(activityStatistic = false) {
    // Fetch activity statis tic

    if (activityStatistic) {
      apiCriteria.setQueries({ activity_statistic: true })
    }

    try {
      isLoading.value = true
      const { data } = await api.fetchApplicationList(apiCriteria.toQuery())

      if (data && data.data) {
        listData.value = data.data

        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false
      return listData
    } catch (error) {
      isLoading.value = false
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        listApplications(true)
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        listApplications(true)
      }
    },
    { deep: true }
  )

  /**
   * @deprecated
   * @returns
   */
  async function getApplicationList(params) {
    try {
      const { data } = await api.fetchApplicationList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : t("setting.errors.list"))
    }
  }
  /**
   *
   * @returns
   */
  async function getAllApplicationList() {
    try {
      const { data } = await api.fetchAllApplication()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : t("setting.errors.list"))
    }
  }

  /**
   * Download applications
   * @returns
   */
  async function downloadApplications() {
    try {
      const { data } = await api.downloadApplications()

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("application.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Application_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("application.errors.download"))
      return false
    }
  }

  return {
    listApplications,
    getApplicationList,
    getAllApplicationList,
    isLoading,
    apiCriteria,
    listData,
    currentPage,
    downloadApplications,
  }
}
