<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("resiliencePlan.resiliencePlans") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Category Tabs -->
    <ResiliencePlanSubMenu @menuChanged="categorySelected" />

    <!-- Filter-->
    <EcBox class="items-center mt-6 grid grid-cols-1 md:grid-cols-2">
      <!-- Filter -->
      <EcFlex class="items-center">
        <!-- From -->
        <RDataFilter
          ref="filterBoxRef"
          v-model="apiCriteria"
          :filterOptions="filterOptions"
          @appliedFilters="getResiliencePlanList"
          :clearTrigger="selectedCategory"
        />
      </EcFlex>

      <!-- Actions -->
      <EcFlex class="justify-center md:justify-end mt-6 md:mt-0">
        <!-- Add ResiliencePlan -->
        <EcButton
          v-permissions:resilience_plan-create
          class="mb-3 lg:mb-0"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickAddResiliencePlan"
        >
          {{ $t("resiliencePlan.buttons.addPlan") }}
        </EcButton>
        <!-- Export ResiliencePlans -->
        <EcButton
          v-permissions:resilience_plan-download
          class="col-end-2 ml-2 mb-3 lg:mb-0"
          :iconPrefix="exportResiliencePlansIcon"
          variant="primary-sm"
          @click="handleClickDownloadResiliencePlans"
        >
          {{ $t("resiliencePlan.buttons.exportPlans") }}
        </EcButton>
      </EcFlex>
    </EcBox>

    <!-- Table -->
    <EcTable
      ref="dataTableRef"
      name="tbl-resilience-plan-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="filteredResiliencePlans"
      :actionMenu="true"
      :menuItems="menuItems"
      @sorted="handleSort"
    >
      <template v-slot:status-name="{ item }">
        <EcFlex class="w-full items-center justify-center">
          <EcBadge :class="[item?.status?.tag_color, item?.status?.text_color, 'px-1 py-1 rounded-full min-w-32']">
            {{ item?.status?.name }}
          </EcBadge>
        </EcFlex>
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (perPage) => {
            apiCriteria.setPageSize(perPage)
          }
        "
      />
    </EcFlex>

    <!-- Modal  delete ResiliencePlan -->
    <teleport to="#layer1">
      <!-- Delete-->
      <EcPopup
        v-model="isModalDeleteOpen"
        type="danger"
        :footerBorder="false"
        :title="$t('resiliencePlan.labels.confirmToDelete')"
        :bodyText="$t('resiliencePlan.labels.confirmDeleteQuestion')"
        :bodySubText="toDeleteItem?.name"
        @overlayClick="handleCloseDeleteModal"
        accept="Delete"
        @onAccept="handleDeleteResiliencePlan"
      >
      </EcPopup>
    </teleport>
  </RLayout>
</template>

<script>
import { useResiliencePlanList } from "@/modules/resilience_plan/use/plan/useResiliencePlanList"
import { useResiliencePlanExport } from "@/modules/resilience_plan/use/plan/useResiliencePlanExport"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import { useResiliencePlanStatus } from "@/readybc/composables/use/useResiliencePlanStatus.js"
import ResiliencePlanSubMenu from "@/modules/resilience_plan/components/ResiliencePlanSubMenu.vue"
import { goto } from "@/modules/core/composables"
import { useResiliencePlanDetail } from "../../use/plan/useResiliencePlanDetail"

export default {
  name: "ViewResiliencePlanList",
  setup() {
    // Pre load
    const globalStore = useGlobalStore()
    const { getResiliencePlanList, handleSort, resiliencePlans, isLoading, apiCriteria, handleSortAndFilter, selectedCategory } =
      useResiliencePlanList()

    const { deleteResiliencePlan } = useResiliencePlanDetail()
    const { downloadResiliencePlans, exportResiliencePlanRecord } = useResiliencePlanExport()

    const { statuses } = useResiliencePlanStatus()

    return {
      globalStore,

      // Get list
      selectedCategory,
      isLoading,
      getResiliencePlanList,
      deleteResiliencePlan,

      // Download
      downloadResiliencePlans,
      exportResiliencePlanRecord,
      handleSort,
      resiliencePlans,

      apiCriteria,
      statuses,
      handleSortAndFilter,
    }
  },
  data() {
    // Date filter
    const lastMonth = new Date()

    lastMonth.setMonth(lastMonth.getMonth() - 1)

    const dateFilter = ref({
      from: lastMonth.toISOString().slice(0, 10),
      to: new Date().toISOString().slice(0, 10),
    })

    return {
      headerData: [
        { key: "name", title: this.$t("resiliencePlan.labels.name"), sortable: true },
        {
          key: "status.name",
          title: this.$t("resiliencePlan.labels.status"),
          sortable: true,
          sortKey: "status_id",
        },
        { key: "report_count", title: this.$t("resiliencePlan.labels.report_num") },
        {
          key: "review_date",
          title: this.$t("resiliencePlan.labels.reviewDate"),
          sortable: true,
          date: true,
          dateFormat: "DD/MM/YYYY",
        },
        {
          key: "due_date",
          title: this.$t("resiliencePlan.labels.dueDate"),
          sortable: true,
          date: true,
          dateFormat: "DD/MM/YYYY",
        },
      ],

      menuItems: [
        {
          label: "Export",
          icon: "export",
          iconClass: "text-c1-800",
          callback: this.handleClickExportResiliencePlanRecord,
        },
        {
          label: "Edit",
          icon: "edit",
          callback: this.handleClickEditResiliencePlan,
        },

        {
          label: "Delete",
          icon: "delete",
          iconClass: "text-cError-700",
          callback: this.handleOpenDeleteModal,
        },
      ],

      filterOptions: [
        {
          key: "name",
          title: this.$t("resiliencePlan.labels.name"),
        },
        {
          key: "review_date",
          title: this.$t("resiliencePlan.labels.reviewDate"),
          type: "date",
        },
        {
          key: "due_date",
          title: this.$t("resiliencePlan.labels.dueDate"),
          type: "date",
        },
      ],

      isLoadingCategories: false,
      isDownloading: false,
      isModalDeleteOpen: false,
      toDeleteItem: null,
      dateFilter,
      recordLoading: [],
    }
  },

  async mounted() {
    await this.apiCriteria.bindCriteria()
    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
  },

  computed: {
    /**
     * Format date
     */
    dateFormat() {
      return this.globalStore.dateFormat
    },

    /**
     * Export Icone
     */
    exportResiliencePlansIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },

    /**
     * Filtered
     */
    filteredResiliencePlans() {
      return this.resiliencePlans
    },
  },

  methods: {
    categorySelected(payload) {
      this.selectedCategory = payload
    },

    // Handle events
    /**
     * Download
     */
    async handleClickDownloadResiliencePlans() {
      this.isDownloading = true
      await this.downloadResiliencePlans(this.selectedCategory)
      this.isDownloading = false
    },

    /**
     *
     * @param {*} uid
     */
    async handleClickExportResiliencePlanRecord(item) {
      await this.exportResiliencePlanRecord(item.uid)
    },

    /**
     * Add new activity
     */
    handleClickAddResiliencePlan() {
      goto("ViewResiliencePlanNew", {
        params: {
          cuid: this.selectedCategory?.uid,
        },
      })
    },

    /**
     *
     * @param {*} resourceUid
     */
    handleClickEditResiliencePlan(item) {
      goto("ViewResiliencePlanDetail", {
        params: {
          uid: item?.uid,
        },
      })
    },

    /**
     * Open delete resource modal
     */
    handleOpenDeleteModal(item) {
      this.toDeleteItem = item
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete resource modal
     */
    handleCloseDeleteModal() {
      this.toDeleteItem = null
      this.isModalDeleteOpen = false
      this.getResiliencePlanList()
    },

    /**
     *
     * @param {*} item
     */
    handleDeleteResiliencePlan() {
      if (this.toDeleteItem) {
        this.deleteResiliencePlan(this.toDeleteItem.uid).then((res) => {
          this.handleCloseDeleteModal()
        })
      }
    },
    // ==== PRE-LOAD ==========
  },
  components: { ResiliencePlanSubMenu },
}
</script>
