<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("report.title") }}
        </EcHeadline>
      </EcFlex>

      <!-- action button -->
      <EcFlex class="flex w-full">
        <EcText class="text-base mt-2">Please choose a <b>Report Type</b> to load the appropriate template</EcText>
      </EcFlex>

      <EcFlex class="flex w-full items-center gap-2 mt-2">
        <!-- Template selector -->
        <EcBox class="max-w-[300px]">
          <EcSelect
            v-model="reportType"
            componentName="EcSelect"
            :options="reportTypes"
            :allowSelectNothing="false"
            :label="$t('report.modal.reportType')"
            @change="handleChangeReportType"
          />
        </EcBox>
        <EcBox v-if="reportType !== ''" class="max-w-[300px]">
          <EcSelect
            v-model="selectedReportTemplateUid"
            componentName="EcSelect"
            :options="reportTemplates"
            :allowSelectNothing="false"
            :label="$t('report.modal.reportTemplate')"
          />
        </EcBox>
        <EcBox v-if="isAllowToGenerateReport">
          <EcButton
            v-if="reportType?.length > 0"
            class="mb-3 lg:mb-0"
            id="btn-advance-options"
            iconPrefix="Filter"
            variant="primary-sm"
            @click="
              () => {
                isModalSbuSelectOpen = true
              }
            "
          >
            {{ appliedFilterLabel }}
          </EcButton>
        </EcBox>
        <EcBox class="grid grid-cols-1 sm:grid-cols-1 gap-4 justify-end">
          <!-- generate report -->
          <EcButton
            v-if="hasTemplate && !isLoading && !isReportDocumentGenerated"
            class="mb-3 lg:mb-0"
            id="btn-generate-report"
            iconPrefix="Report"
            variant="primary-sm"
            @click="handleGenerateReport(false)"
          >
            {{ $t("report.buttons.generateReport") }}
          </EcButton>
        </EcBox>
      </EcFlex>
    </EcFlex>

    <!-- Viewer -->
    <EcBox class="w-full h-full mt-2">
      <EcFlex class="w-full items-center" v-if="isTriggeredReportGenerator && isAllowToGenerateReport">
        <EcIcon icon="Report" width="14"></EcIcon>
        <EcLabel v-if="isFetchingReportDocument" class="ml-2 text-sm"> {{ $t("report.labels.reportGenerating") }}</EcLabel>
        <EcLabel v-if="isLoading || (!isFetchingReportDocument && generatedReport?.document)" class="ml-2 text-sm">
          {{ $t("report.labels.reportGenerated") }}</EcLabel
        >
        <EcLabel v-if="isReportDocumentGenerated && generatedReportHasError" class="ml-2 text-sm">
          {{ $t("report.labels.reportGenerateError") }}</EcLabel
        >
        <EcLabel v-if="generatedReport" @click="gotoReportDetail(generatedReport)" class="ml-2 text-sm underline cursor-pointer">
          {{ $t("report.buttons.viewReport") }}</EcLabel
        >
      </EcFlex>

      <EcFlex v-if="isFetchingReportDocument">
        <EcSpinner class="m-4" />
        <span class="mt-4 text-sm min-w-48 italic">{{ $t("report.labels.loadingReport") }}</span>
      </EcFlex>
    </EcBox>

    <EcBox class="w-full h-full">
      <EcFlex class="w-full items-center" v-if="!isLoading && !isTriggeredReportGenerator && isAllowToGenerateReport">
        <EcIcon icon="QuestionMark" width="14"></EcIcon>
        <EcLabel class="ml-2 mt-2 text-sm"> {{ $t("report.labels.templateHelp") }}</EcLabel>
      </EcFlex>

      <!-- EcFlex background -->
      <EcBox class="w-full mt-10" v-if="!isAllowToGenerateReport">
        <EcFlex class="flex-col w-full vh-[70%] items-center justify-center">
          <img class="w-[40%] mt-10" src="@/assets/images/report_bg.svg" />
        </EcFlex>
      </EcBox>

      <EcFlex v-if="isLoading && isTriggeredReportGenerator && isAllowToGenerateReport">
        <EcSpinner class="m-4" />
        <span class="mt-4 text-sm min-w-48 italic">Document generating...</span>
      </EcFlex>

      <!-- Report Template Preview -->
      <EcDocumentPreview
        v-if="isAllowToGenerateReport && !isTriggeredReportGenerator"
        :document="reportTemplateDocument"
        :embbededMode="true"
      />

      <EcFlex
        v-if="!isLoading && !reportTemplateDocument && isAllowToGenerateReport"
        class="mt-4 text-base w-full justify-center"
      >
        {{ $t("report.labels.somethingWrong") }}
      </EcFlex>

      <!-- Report Preview-->
      <EcDocumentPreview
        v-if="isAllowToGenerateReport && isTriggeredReportGenerator && generatedReport?.document"
        :document="generatedReport?.document"
      />
      <EcFlex
        v-if="isAllowToGenerateReport && isTriggeredReportGenerator && !generatedReport"
        class="mt-4 text-base w-full justify-center"
      >
        {{ $t("report.labels.somethingWrong") }}
      </EcFlex>
    </EcBox>

    <!-- End editor-->
  </RLayout>

  <!-- Modal Delete -->
  <teleport to="#layer1">
    <ModalReportSbuSelection
      :isModalOpen="isModalSbuSelectOpen"
      @close="handleCloseModalSbuSelection"
      @handleApplyFilters="handleApplyFilters"
    >
    </ModalReportSbuSelection>
  </teleport>
  <Teleport to="#layer2">
    <EcPopConfirm
      v-model="isShowWarning"
      :title="$t('report.modal.noLimit')"
      :confirmLabel="$t('report.modal.confirmLabel')"
      @onConfirm="handleGenerateReport(true)"
    ></EcPopConfirm>
  </Teleport>
</template>

<script>
import ModalReportSbuSelection from "@/modules/report/components/ModalReportSbuSelection"
import { useReportTemplate } from "../use/useReportTemplate"
import { useModalReportSbuSelection } from "../use/useModalReportSbuSelection"

import { reactive } from "vue"
import { useReportGenerator } from "../use/useReportGenerator"
import { useI18n } from "vue-i18n"
import EcDocumentPreview from "@/components/EcDocumentPreview/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import { useReport } from "@/modules/report/use/useReport"

export default {
  name: "GenerateReport",
  data() {
    return {
      isLoading: false,
      isLoadingTemplate: false,
      isFrameLoading: false,
      hasTemplate: false,
      isModalSbuSelectOpen: false,
      reportType: "",
      reportTemplateDocument: null,
      availableReportTemplates: [],
      selectedReportTemplateUid: null,
      selectedReportTemplate: null,
      reportTemplateRevisions: [],
      filterData: reactive({}),
      appliedFilterLabel: this.$t("report.buttons.advanceOptions"),
      isShowWarning: false,
      isReportDocumentGenerated: false,
      isFetchingReportDocument: false,
      fetchReportDocumentCount: 0,
      maxRetryFetchReportDocument: 20,
      generatedReportHasError: false,
    }
  },
  setup() {
    const { getRunnableReportTemplateTypes, getReportTemplateDefaultRevision, getTemplate } = useReportTemplate()
    const { generatedReport, generateReport, isTriggeredReportGenerator } = useReportGenerator()
    const { fetchReportDetails } = useReport()
    const { isGeneratingReport } = useModalReportSbuSelection()
    const { t } = useI18n()

    return {
      getTemplate,
      getRunnableReportTemplateTypes,
      getReportTemplateDefaultRevision,
      fetchReportDetails,
      isGeneratingReport,
      generatedReport,
      generateReport,
      isTriggeredReportGenerator,
      t,
    }
  },

  beforeMount() {
    this.fetchTemplateTypes()
  },

  computed: {
    reportTypes() {
      const options = [
        {
          value: "",
          name: "Select Report Type",
        },
      ]

      for (const key in this.availableReportTemplates) {
        const item = this.availableReportTemplates[key]
        options.push({
          value: item.type,
          name: item.report_name,
        })
      }
      return options
    },
    reportTemplates() {
      const options = [
        {
          value: "",
          name: "Select Report Template",
        },
      ]

      for (const key in this.reportTemplateRevisions) {
        const item = this.reportTemplateRevisions[key]
        options.push({
          value: item.uid,
          name: item.name,
        })
      }
      return options
    },
    isAllowToGenerateReport() {
      return this.reportType && this.reportType !== "" && this.selectedReportTemplateUid
    },
  },
  methods: {
    gotoReportDetail(report) {
      if (!report) {
        return
      }

      const { href } = this.$router.resolve({ name: "ViewReportDetail", params: { uid: report?.uid } })
      window.open(href, "_blank")
    },

    async handleChangeReportType() {
      await this.fetchTemplateRevision(this.reportType)
    },

    async fetchTemplateTypes() {
      this.isLoading = true
      const response = await this.getRunnableReportTemplateTypes()
      if (response) {
        this.availableReportTemplates = response.data
      }
      this.isLoading = false
    },

    async fetchTemplateRevision(type) {
      this.selectedReportTemplate = null
      this.selectedReportTemplateUid = null

      if (!type) {
        return
      }

      this.isLoading = true
      const response = await this.getReportTemplateDefaultRevision(type)
      if (response) {
        const defaultRevision = response.data
        defaultRevision.name = "[Default] - " + defaultRevision.name
        this.reportTemplateRevisions = [defaultRevision]
      }
      this.isLoading = false
    },

    /**
     * fetch suppliers
     * @returns {Promise<void>}
     */
    async fetchTemplates() {
      this.isLoading = true
      this.hasTemplate = false
      const template = await this.getTemplate(this.reportType, this.selectedReportTemplateUid)

      if (template && template?.data) {
        this.reportTemplateDocument = template?.data?.document
        this.selectedReportTemplate = template?.data
        this.hasTemplate = !!this.reportTemplateDocument
      }

      this.isLoading = false
    },

    /**
     * fetch suppliers
     * @returns {Promise<void>}
     */
    async handleGenerateReport(force = false) {
      if (
        !force &&
        (!this.filterData?.selectedSBUs || this.filterData?.selectedSBUs?.length <= 0) &&
        (!this.filterData?.activityCreatedAtObj?.start || this.filterData?.activityCreatedAtObj?.start?.length <= 0) &&
        (!this.filterData?.activityCreatedAtObj?.end || this.filterData?.activityCreatedAtObj?.end?.length <= 0)
      ) {
        this.isShowWarning = true
        return
      }

      this.isTriggeredReportGenerator = true
      this.isLoading = true
      this.handleCloseModalSbuSelection()
      await this.generateReport(this.reportType, this.selectedReportTemplateUid, this.filterData)
      this.isLoading = false
    },

    async fetchReportDocument(uid) {
      this.isFetchingReportDocument = true
      this.fetchReportDocumentCount = 0
      const interval = setInterval(async () => {
        this.loading = true
        const response = await this.fetchReportDetails(uid)
        if (response && response?.data?.document) {
          this.generatedReport.document = response?.data?.document
          clearInterval(interval)
          this.stopFetchReportDocument()
          return
        }

        if (this.generatedReport.status === "GenerateFailed") {
          clearInterval(interval)
          this.stopFetchReportDocument()
          this.generatedReportHasError = true
        }

        this.fetchReportDocumentCount += 1

        if (this.fetchReportDocumentCount > this.maxRetryFetchReportDocument) {
          clearInterval(interval)
          this.stopFetchReportDocument()
        }
        this.loading = false
      }, 5 * 1000)
    },

    stopFetchReportDocument() {
      this.isReportDocumentGenerated = true
      this.isFetchingReportDocument = false
      this.fetchReportDocumentCount = 0
    },

    /**
     * Apply filters
     */
    handleApplyFilters(filterData) {
      this.filterData = filterData

      this.advanceOptionsLabel()
    },

    /**
     * close modal
     */
    handleCloseModalSbuSelection() {
      this.isModalSbuSelectOpen = false
    },

    /**
     * Advance option label
     */
    advanceOptionsLabel() {
      let appliedFilterCnt = 0

      if (this.filterData?.selectedSBUs?.length > 0) {
        appliedFilterCnt++
      }
      if (this.filterData?.activityCreatedAtObj?.start?.length > 0 || this.filterData?.activityCreatedAtObj?.end?.length > 0) {
        appliedFilterCnt++
      }

      if (appliedFilterCnt > 0) {
        this.appliedFilterLabel = `Applied ${appliedFilterCnt} filter(s)`
      } else {
        this.appliedFilterLabel = this.t("report.buttons.advanceOptions")
      }
    },
  },

  components: { EcFlex, EcDocumentPreview, ModalReportSbuSelection },

  watch: {
    isAllowToGenerateReport(val) {
      if (val) {
        this.fetchTemplates()
      }
    },
    generatedReport(newVal, old) {
      if (newVal?.uid && newVal?.uid !== old?.uid) {
        this.fetchReportDocument(newVal?.uid)
      }
    },
  },
}
</script>
