import { reactive, ref, watch } from "vue"
import * as api from "../../api/equipmentFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useEquipmentList() {
  const globalStore = useGlobalStore()

  const equipmentList = ref([])
  const apiCriteria = reactive(new ApiCriteria())
  const isLoading = ref(false)

  const { t } = useI18n()

  // Default size
  apiCriteria.pagination.size = 20

  /**
   *
   * @returns
   */
  async function getEquipmentList(activityStatistic = false) {
    try {
      if (activityStatistic) {
        apiCriteria.setQueries({ activity_statistic: true })
      }

      isLoading.value = true
      const { data } = await api.fetchEquipmentList(apiCriteria.toQuery())

      if (data && data.data) {
        equipmentList.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.listEquipments"))
      isLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getEquipmentList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getEquipmentList()
      }
    },
    { deep: true }
  )

  /**
   * Column Sorting
   */
  const handleColumnSort = async (col, sorted) => {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await getEquipmentList()
  }

  /**
   *
   * @returns
   */
  async function getAllEquipments() {
    try {
      const { data } = await api.fetchAllEquipments()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.listEquipments"))
    }
  }

  /**
   * Download equipments
   * @returns
   */
  async function downloadEquipments(categoryUid) {
    try {
      const { data } = await api.downloadEquipments(categoryUid)

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("equipment.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Equipment_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.download"))
      return false
    }
  }

  return {
    getEquipmentList,
    handleColumnSort,
    downloadEquipments,
    getAllEquipments,
    equipmentList,
    apiCriteria,
    isLoading,
    t,
  }
}
