<template>
  <!-- container -->
  <div :class="[variantCls.container]">
    <!-- title -->
    <div v-if="title?.length > 0">
      <h2 :class="[variantCls.title]">{{ title }}</h2>
    </div>

    <!-- wrapper -->
    <div :class="[variantCls.wrapper]">
      <!-- table wrapper -->
      <div :class="[variantCls.tableWrapper]">
        <table :class="[variantCls.table, name]">
          <thead>
            <tr>
              <th
                v-for="(col, colIdx) in columns"
                :key="col?.key"
                :class="[variantCls.headerCell, `text-${col?.headerAlign ?? 'left'}`]"
                class="relative"
              >
                <span :class="col?.sortable ? 'mr-3' : ''">{{ col?.title }}</span>

                <!-- Sorting -->
                <span
                  v-if="col?.sortable"
                  :class="[variantCls.sortIcon, getSortColor(col, colIdx)]"
                  @click="handleClickSortColumn(col, colIdx)"
                >
                  <SortIcon :icon="getSortIcon(col, colIdx)" />
                </span>

                <!-- End sorting -->
              </th>
              <th v-if="actionMenu" :class="[variantCls.headerCell]"></th>
            </tr>
          </thead>

          <tbody v-if="!isLoading">
            <tr v-if="dataSource?.length <= 0">
              <td :colspan="columns?.length" class="text-center text-c1-400 text-base">
                <slot name="nodata"> No data found</slot>
              </td>
            </tr>
            <!-- Data -->
            <tr v-else v-for="item in dataSource" :key="item?.key" :class="[variantCls.bodyRow, rowCustomCss(item)]">
              <!-- Slot for row -->
              <slot name="row" :item="item">
                <!-- Slot for data cell -->
                <td
                  v-for="column in columns"
                  :key="column?.key"
                  :class="[variantCls.bodyCell, getColumnSlotName(column)]"
                  @click="rowClicked(item)"
                >
                  <slot :item="item" :column="column" :name="getColumnSlotName(column)">
                    {{ getFiledData(item, column) }}
                  </slot>
                </td>

                <!-- Start menu -->
                <td class="row-menu-cell" :class="variantCls.bodyCell" v-if="actionMenu">
                  <slot name="actionMenu" :item="item">
                    <div :class="variantCls.actionMenu.button">
                      <!-- Menu-->
                      <ActionMenu
                        :item="item"
                        :variantCls="variantCls"
                        :menuItems="menuItems"
                        :defaultMenuItemVisible="defaultMenuItemVisible"
                        @onExport="handleClickExporttRow(item)"
                        @onEdit="handleClickEditRow(item)"
                        @onDelete="handleClickDeleteRow(item)"
                      ></ActionMenu>
                    </div>
                  </slot>
                </td>
                <!-- End action menu -->
              </slot>

              <!-- End slot for row -->
            </tr>
          </tbody>
        </table>
      </div>

      <!-- end table wrapper -->
    </div>

    <div v-if="isLoading" :class="variantCls.spinner.wrapper">
      <slot name="spinner">
        <Icon icon="spinner" width="24" height="24" />
      </slot>
    </div>

    <!-- end wrapper -->
  </div>

  <!-- end container -->
</template>

<script>
import Icon from "./Icon"
import SortIcon from "./Icon/SortIcon"
import ActionMenu from "./ActionMenu"
import get from "lodash.get"
import { formatDate } from "@/helpers/formater"
import { reactive } from "vue"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export default {
  name: "EcTable",
  emits: ["sorted", "onExport", "onEdit", "onDelete"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    headerAlign: {
      type: String,
      default: "left",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },

    dataSource: {
      type: Array,
      default: () => [],
    },

    actions: {
      type: Array,
      default: () => [],
    },

    rowClicked: {
      type: Function,
      default: (item) => {},
    },

    rowCustomCss: {
      type: Function,
      default: (item) => "",
    },

    actionMenu: {
      type: Boolean,
      default: false,
    },

    menuItems: {
      type: Array,
      default: () => [],
    },

    defaultMenuItemVisible: {
      type: Object,
      default: () => {
        return {
          edit: true,
          delete: true,
        }
      },
    },
  },

  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcTable",
          variant: this.variant,
        })?.el ?? {}
      )
    },
  },
  data() {
    return {
      sortingStatues: reactive([]),
      actionMenuStatues: reactive([]),
      isMenuOpen: false,
      currentSortColumn: {},
    }
  },

  created() {
    this.sortingStatues = new Array(this.columns?.length).fill(true)
  },
  methods: {
    /**
     *
     * @param {*} item
     * @param {*} filedName
     */
    getFiledData(item, col) {
      const fieldValue = get(item, col.key)

      if (col?.date) {
        return formatDate(fieldValue, col?.dateFormat || "DD/MM/YYYY HH:mm:ss")
      }
      return fieldValue
    },

    /**
     *
     * @param {*} col
     * @param {*} colIdx
     */
    handleClickSortColumn(col, colIdx) {
      const sorted = !this.sortingStatues[colIdx]

      // Reset other colums
      this.sortingStatues = new Array(this.columns?.length).fill(true)

      this.sortingStatues[colIdx] = sorted

      if (this.columns[colIdx]?.sortCallback) {
        this.columns[colIdx].sortCallback(col, colIdx)
      }
      this.setColumnActiveSort(col.key, sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC)

      this.$emit("sorted", col, sorted)
    },

    /**
     *
     * @param {*} col
     * @param {*} colIdx
     */
    getSortIcon(col, colIdx) {
      if (this.currentSortColumn && (this.currentSortColumn?.key === col.key || this.currentSortColumn?.key === col.sortKey)) {
        if (col?.numeric) {
          return this?.currentSortColumn?.direction === SortDirectionEnum.ASC ? "09" : "90"
        }
        return this?.currentSortColumn?.direction === SortDirectionEnum.ASC ? "AZ" : "ZA"
      }

      if (col?.numeric) {
        return "09"
      } else {
        return "AZ"
      }
    },

    /**
     *
     * @param {*} col
     * @param {*} colIdx
     */
    getSortColor(col, colIdx) {
      if (this.currentSortColumn && (this.currentSortColumn?.key === col.key || this.currentSortColumn?.key === col.sortKey)) {
        return "text-cError-700"
      }

      return "text-cBlack"
    },

    /**
     *
     * @param {*} item
     */
    handleClickExporttRow(item) {
      this.$emit("onExport", item)
    },

    /**
     *
     * @param {*} item
     */
    handleClickEditRow(item) {
      this.$emit("onEdit", item)
    },

    /**
     *
     * @param {*} item
     */
    handleClickDeleteRow(item) {
      this.$emit("onDelete", item)
    },

    /**
     * Column slot name
     */
    getColumnSlotName(column) {
      const regex = /\./gi

      return column?.slotKey || column?.key?.replaceAll(regex, "-")
    },

    /**
     * Set sort Column
     * @param columnKey
     * @param direction
     */
    setColumnActiveSort(columnKey, direction) {
      if (!columnKey || !direction) {
        return
      }
      this.currentSortColumn = {
        key: columnKey,
        direction,
      }
    },
  },
  watch: {
    dataSource(data) {
      this.actionMenuStatues = new Array(data?.length).fill(false)
    },
  },

  components: { ActionMenu, Icon, SortIcon },
}
</script>
