import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { fetchActivityByDependable } from "@/readybc/composables/api/apiActivityByDependable"
import { reactive, ref, watch } from "vue"
import { useGlobalStore } from "@/stores/global"

export const useModalActivityByDependency = () => {
  const isLoading = ref(false)
  const activities = ref([])
  const apiCriteria = reactive(new ApiCriteria())
  const globalStore = useGlobalStore()

  // Dependable
  const dependableType = ref("")
  const dependableUid = ref("")

  /**
   *
   * @param {*} dependable
   * @param {*} uid
   * @param {*} params
   */
  const listActivityByDependable = async () => {
    try {
      isLoading.value = true

      const { data } = await fetchActivityByDependable(dependableType.value, dependableUid.value, apiCriteria.toQuery())

      if (data && data.data) {
        activities.value = data.data

        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false
    } catch (e) {
      globalStore.addErrorToastMessage("Unable to fetch linked activities. ")
      isLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        listActivityByDependable()
      }
    },
    { deep: true }
  )

  /**
   * Change current page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        listActivityByDependable()
      }
    },
    { deep: true }
  )

  return {
    isLoading,
    activities,
    apiCriteria,
    dependableType,
    dependableUid,
    listActivityByDependable,
  }
}
