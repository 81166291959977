import dayjs from "dayjs"
import { defineStore } from "pinia"
import { loadLanguageAsync } from "./../setups/i18nConfig"
import apiMe from "@/readybc/composables/api/apiMe"
import { useLogoutStore } from "@/modules/auth/stores/useLogout"
import { useBrowserTabs } from "@/modules/core/use/useBrowserTabs"
import { v4 as uuidv4 } from "uuid"

export const useGlobalStore = defineStore("global", {
  state: () => ({
    tenantId: null,
    clientId: null,
    // Cross route messages for notifications
    messages: [],
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    idleTime: 900,
    tenantSettings: null,
    isFetchingMe: false,
    me: null,
    availableLocales: [],
    organizationId: "6529bd8a-304f-11ed-8505-0242ac120006",
    appUid: uuidv4(),
  }),

  getters: {
    getTenantId() {
      return this.tenantId
    },

    getClientId() {
      return this.clientId
    },

    getMessages() {
      return this.messages
    },

    getTenantClientId() {
      return {
        tenantId: this.tenantId,
        clientId: this.clientId,
      }
    },

    getTenantSettings() {
      return this.tenantSettings
    },

    isLandlord() {
      return this?.tenantSettings?.server?.landlord === true
    },

    isDeveloperUser() {
      const developerUsers = process.env?.VUE_APP_DEVELOPER_USER?.split(",")
      if (developerUsers) {
        return developerUsers.includes(this?.me?.email)
      }
      return false
    },

    getAllowedModuleIDs() {
      return this.tenantSettings?.server?.modules?.map((item) => {
        return item.value
      })
    },

    getTenantName() {
      return this.tenantSettings?.server?.name
    },
    getTenantUid() {
      return this.tenantSettings?.server?.uid
    },

    getDateTimeFormat() {
      return this.dateTimeFormat
    },

    getMe() {
      return this.me
    },
  },

  // Actions
  actions: {
    // Add Toast Message
    addToastMessage({ type, content, cb, delay = 10000, preventAutoClose = false }) {
      const key = new Date().getTime() + ~~(Math.random() * 100000)
      const obj = {}
      obj[key] = { type, content, key, cb }

      this.messages = { ...this.messages, ...obj }
      // Automatically remove messages after a few seconds
      if (preventAutoClose === false) {
        const timeout = setTimeout(() => {
          this.removeToastMessage(key)
          clearTimeout(timeout)
        }, delay)
      }
    },

    // Remove Toast message
    removeToastMessage(payload) {
      if (this.messages[payload]) {
        this.messages[payload].cb && this.messages[payload].cb()
        delete this.messages[payload]
        this.messages = { ...this.messages }
      }
    },

    /**
     *
     * @param {*} message
     */
    addSuccessToastMessage(message) {
      this.addToastMessage({
        type: "success",
        content: {
          type: "message",
          text: message,
        },
      })
    },
    /**
     *
     * @param {*} message
     */
    addNotifyToastMessage(notify) {
      this.addToastMessage({
        type: "info",
        content: {
          type: "notify",
          message: notify.content,
          title: notify.title,
          uid: notify.id,
        },
      })
    },

    /**
     *
     * @param {*} message
     */
    addErrorToastMessage(message) {
      if (!message) {
        message = "Opps! Some error has occured"
      }

      this.addToastMessage({
        type: "error",
        content: {
          type: "message",
          text: message,
        },
      })
    },

    /**
     *
     * @returns
     */
    getUserNotificationCnt() {
      const currentNotiCnt = parseInt(this.me?.unread_notification_number)

      return isNaN(currentNotiCnt) ? 0 : currentNotiCnt
    },

    /**
     * Increase notification count
     * @param {*} value
     */
    increaseNotificationCnt(value) {
      this.me.unread_notification_number = this.getUserNotificationCnt() + value
    },

    /**
     * Decrease notification count
     * @param {*} value
     */
    decreaseNotificationCnt(value) {
      if (this.getUserNotificationCnt() >= value) {
        this.me.unread_notification_number = this.getUserNotificationCnt() - value
      } else {
        this.me.unread_notification_number = 0
      }
    },

    /**
     * setMe
     *
     */
    async setMe() {
      if (!this.me) {
        const { data } = await apiMe.fetchMe()

        if (data) {
          this.me = data
        }
      }
    },

    /**
     *
     * @param {*} role
     * @returns
     */
    hasRole(role) {
      return (
        this.me?.roles?.filter((item) => {
          return item.name === role
        }).length > 0
      )
    },

    // For multi-tenants if needed
    setTenantId(payload) {
      this.tenantId = payload
      localStorage.setItem("readyBCAdminTenantId", payload)
    },

    // Set client id
    setClientId(payload) {
      this.clientId = payload
      localStorage.setItem("readyBCAdminClientId", payload)
    },

    // Set Tenant Settings
    setTenantSettings(payload) {
      this.tenantSettings = payload
    },
    // Set Tenant IDLE time
    setTenantIDLETime(payload) {
      this.idleTime = payload
    },

    setLocale(locale) {
      loadLanguageAsync(locale)
    },

    // Set setDateFormat
    setDateFormat(payload) {
      this.dateFormat = payload
    },

    // setDateTimeFormat
    setDateTimeFormat(payload) {
      this.dateTimeFormat = payload
    },

    /**
     *
     * @param {*} dateStr
     * @returns
     */
    formatDate(dateStr) {
      if (!dateStr) return "-"

      // If dayjs || Date object
      if (this.dateFormat) {
        const dayObj = dayjs(dateStr)
        if (dayObj.$d.toString() !== "Invalid Date") {
          return dayObj.format(this.dateFormat)
        }
      }
      return dateStr
    },

    /**
     *
     * @param {*} dateTimeStr
     * @returns
     */
    formatDateTime(dateTimeStr) {
      if (!dateTimeStr) return "-"

      // If dayjs || Date object
      if (this.dateFormat) {
        const dayObj = dayjs(dateTimeStr)
        if (dayObj.$d.toString() !== "Invalid Date") {
          return dayObj.format(this.dateTimeFormat)
        }
      }
      return dateTimeStr
    },
    // Check is on public route
    isOnPublicRoute() {
      const publicRoutes = ["ViewLogin", "ViewForgotPassword", "ViewConfirmForgotPassword", "ViewNewPassword", "ViewMFA"]
      return publicRoutes.includes(this.router.currentRoute.value.name)
    },
    // Logout
    logout() {
      const logoutStore = useLogoutStore()
      const browserTabs = useBrowserTabs()

      logoutStore.logout()
      // Reset isIdle status
      browserTabs.setAllBrowserTabData("isIdle", false)

      if (this.clientId !== "escalate") {
        this.router.push(`/login?tenantId=${this.tenantId}&?clientId=${this.clientId}`)
      } else {
        this.router.push(`/login?tenantId=${this.tenantId}`)
      }

      this.me = null
    },
  }, // End Actions
})
