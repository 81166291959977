export default {
  setting: {
    settings: "Settings",
    profile: "Profile",
    tenantId: "Tenant ID",
    language: "Language",
    accountInfo: "Account Information",
    fullName: "Full Name",
    email: "Email",
    changePassword: "Change Password",
    password: "Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    updateNameSuccessNote: "Your full name has been updated successfully.",
    updatePasswordSuccessNote: "Your password has been updated successfully.",
    editFor: "Edit for",
    pleaseSelect: "Please Select",
    displayLanguage: "Display Language",
    confirmToDelete: "Confirm to Delete",
    update: "Update",
    cancel: "Cancel",

    labels: {
      general: "General",
      eventNoti: "Event Notifications",
      mail: "Mail Settings",
      notificationTemplates: "Managed Templates",
      reportTemplates: "Report Templates",
    },
    general: {
      generalSetting: "General Setting",
      matterData: "Master Data",
      timeout: "Threshold for idle user timeout",
      theme: "Tenant theme",
      timezone: "Timezone",
      masterDataModules: {
        utilities: "Utilities List",
        remoteAccess: "Remote Access Factor List",
        application: "Application List",
        software: "Software List",
        equipment: "Activity Equipment List",
        mtdp: "Maximum Tolerable Period of Disruption (MTPD) List",
        rto: "Recovery Time Objective (RTO) List",
        priorities: "Activity Priorities",

        // BIA
        biaStatus: "BIA Statuses List",

        // BCP
        bcpStatus: "BCP Statuses List",
        supplierStatus: "Supplier Statuses List",

        // resilience Plan
        resiliencePlanCategories: "Resilience Plan Categories",
        resiliencePlanStatuses: "Resilience Plan Statuses",
        locations: "Locations",
        activityOwners: "Activity Owners",
      },
      labels: {
        name: "Name",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        description: "Description",
        value: "value",
        order: "Order",
        createdAt: "Created At",
        textColor: "Text Color",
        isDefault: "Default",
        preview: "Preview",
        lorem: "Lorem Ipsum",
        bgColor: "Background Color",
        edit: "Edit",
        detail: "Detail",
        priorityOrder: "The order for priorities, greater number is higher priority",
        icon: "Icon",
        confirmDelete: "This action cannot be reverted. Are you sure you want to delete this data?",
        noteDelete:
          "You can permanently delete the data from database. This action cannot be reverted and you cannot restore the record anymore.",
        valueInHour: "Value (In hour)",
        yes: "Yes",
        no: "No",
        idp: "Simplify user authentication with centralized portal",
        live_notification: "Live Notification",
        allow_duplicated_sbu_name: "Allow duplicated SBU names",
        allow_tenant_generates_report: "Allow organisation users to generate reports",
        only_landlord_user_can_generates_report: "Allow landlord users to generate reports",
      },
      buttons: {
        addRTO: "New Recovery Times",
        addMTDP: "New Tolerable Period of Disruption",
        addActivityEquipment: "New Activity Equipment",
        addApplication: "New Application/Software",
        addRemoteAccess: "New Remote Access Factor",
        addUtilities: "New Utility",
        addActivityPriority: "New Priority",
        addBIAStatus: "New BIA Status",
        addBCPStatus: "New BCP Status",
        addSupplierStatus: "New Supplier Status",
        addLocation: "New Location",
        addActivityOwner: "New Activity Owner",
        delete: "Delete",
        cancel: "Cancel",
        create: "Create",
        edit: "Edit",
        back: "Back",
      },
      messages: {
        createSuccess: "Create Success",
        saveSuccess: "Save Setting Success",
        createError: "Create Error. Please try again.",
        updateProfilePictureSuccess: "Update Profile Picture Success",
      },
    },
    mail: {
      enabled: "Enable Organisation Email Settings",
      yes: "Yes",
      no: "No",
      host: "Host",
      port: "Port",
      displayName: "Display Name",
      fromAddress: "From Address",
      mailEncryption: "Mail Encryption",
      username: "Username",
      password: "Password",
      toAddress: "To Address",
      tls: "Enable StartTSL",
      auth: "Enable Authentication",
      updatedEmailSetting: "Updated email setting successfully ",
      testEmailSetting: "Test email connection successfully ",
      errors: {
        get: "We are unable to get email setting, please try again",
      },

      buttons: {
        test: "Test Email Settings",
      },
      tooltip: {
        test: "This will send a testing email with input email configuration",
      },
    },

    mfa: {
      title: "MFA",
      enabled: "Enabled",
      disabled: "Disabled",
      labels: {
        status: "Status",
        mfa: "MFA",
        qr: "Sign in by scanning QR code",
        code: "OTP Code",
      },
      buttons: {
        confirm: "Confirm",
        cancel: "Cancel",
      },
      messages: {
        success: "Success!",
      },
      errors: {
        loadQR: "We are unable to get MFA verification, please try again",
        list: "We are unable to get data, please try again",
      },
      tooltips: {
        copy: "Click to the image to copy URI and paste to authenticator apps in case cannot scan",
      },
    },
    sortBy: {
      sortBy: "Sort By",
      sortByValue: "Sort By Value",
      sortByName: "Sort By Name",
      sortByDateCreated: "Sort By Date Created",
    },
  },
}
